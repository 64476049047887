"use client";

import * as Dialog from "@radix-ui/react-dialog";
import useSWR from "swr";
import { useState } from "react";
import { fetcher } from "@utils/fetchers";
import debounce from "lodash/debounce";
import Image from "next/image";
import {
  ArrowRightIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import { AnimatePresence, motion } from "framer-motion";
import LoadingSpinner from "./loadingSpinner";
import { useRouter } from "next/navigation";
import { joinClassNames } from "@utils/helpers";
import { useWindowSize } from "@utils/hooks";

interface LandingPage {
  Name: string;
  Url: string;
  ImageUrl: string;
}

interface Event {
  Name: string;
  Url: string;
  ImageUrl: string;
  Date: string;
  Venue: string;
}

interface Props {
  in_jumbotron?: boolean;
  icon?: React.ReactNode;
}

export default function SearchResults({ in_jumbotron, icon }: Props) {
  const router = useRouter();
  const windowSize = useWindowSize();
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const { data: results, isLoading } = useSWR<{
    Query: string;
    Events: Event[];
    LandingPages: LandingPage[];
  }>(query ? `search/quick/${query}` : null, fetcher, {
    onErrorRetry: (err, _key, _config, revalidate, revalidateOpts) => {
      if (err.response.status === 429) {
        return setTimeout(
          () => revalidate(revalidateOpts),
          err.response.headers["retry-after"] * 1000
        );
      }

      return;
    },
    keepPreviousData: query ? true : false,
  });

  const debouncedSetQuery = debounce((q: string) => setQuery(q), 250);

  function getRelativePath(url: string) {
    const rel_path = `/events/${url.split("/events/")[1]}`;

    return rel_path;
  }

  const INPUT_HEIGHT = 56;
  const CONTENT_MARGIN = 32;
  const SEE_MORE_HEIGHT = 24;
  const BORDER = 2;
  const EVENT_HEIGHT = 117;
  const LANDING_PAGE_HEIGHT = 85;
  const LANDING_PAGE_GAP = 16;
  const DEFAULT = 58;

  const raw_search_box_height =
    results && (results.Events.length > 0 || results.LandingPages.length > 0)
      ? INPUT_HEIGHT +
        CONTENT_MARGIN +
        SEE_MORE_HEIGHT +
        BORDER +
        Math.max(
          results.Events.length * EVENT_HEIGHT,
          results.LandingPages.length * LANDING_PAGE_HEIGHT +
            LANDING_PAGE_GAP * (results.LandingPages.length - 1)
        )
      : DEFAULT;
  const search_box_height =
    windowSize.height && windowSize.width
      ? Math.min(
          windowSize.height * (windowSize.width >= 1024 ? 0.9 : 0.8),
          raw_search_box_height
        )
      : DEFAULT;

  const search_box_top = windowSize.height
    ? windowSize.height / 2 - search_box_height / 2
    : 0;

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) {
          setQuery("");
        }
      }}
    >
      <Dialog.Trigger asChild>
        {in_jumbotron ? (
          <motion.button
            layoutId="search_box"
            transition={{ duration: 0.2 }}
            className="relative flex items-center w-64 pl-8 pr-4 py-2 rounded-md bg-white text-gray-500 focus:outline-none focus:ring focus:ring-indigo-500"
          >
            Search for tickets
            <MagnifyingGlassIcon className="absolute left-2 text-gray-500 w-4 h-4" />
          </motion.button>
        ) : (
          <button aria-label="search-button" aria-controls="radix-:R335j9:">
            {icon ?? (
              <MagnifyingGlassIcon className="text-white h-6 w-6 stroke-2" />
            )}
          </button>
        )}
      </Dialog.Trigger>
      <AnimatePresence>
        {open && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay asChild forceMount>
              <motion.div
                id="search_box_overlay"
                layoutId={in_jumbotron && open ? "search_box" : undefined}
                className="fixed inset-0 bg-black/0 z-50"
              />
            </Dialog.Overlay>
            <Dialog.Content asChild forceMount>
              <motion.div
                layoutId={in_jumbotron && open ? "search_box" : undefined}
                style={{
                  top: `${search_box_top}px`,
                }}
                initial={!in_jumbotron ? { opacity: 0 } : undefined}
                animate={!in_jumbotron ? { opacity: 1 } : undefined}
                exit={!in_jumbotron ? { opacity: 0 } : undefined}
                transition={{ duration: 0.2 }}
                className={joinClassNames(
                  "fixed z-[9999] max-h-[80dvh] lg:max-h-[90vh] w-[calc(100vw-1rem)] lg:w-[48rem] bg-white rounded-2xl overflow-y-auto shadow-modal backdrop-blur border border-gray-200",
                  `left-[calc(50%-50vw+0.5rem)] lg:left-[calc(50%-24rem)]`
                )}
              >
                <motion.div
                  key={isLoading ? "loading" : "searching"}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {isLoading ? (
                    <LoadingSpinner
                      size="18"
                      classes="absolute top-[18px] left-[18px]"
                    />
                  ) : (
                    <MagnifyingGlassIcon className="absolute left-4 top-4 h-6 w-6 text-gray-500" />
                  )}
                </motion.div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setQuery("");
                    const { search_query } = Object.fromEntries(
                      new FormData(e.currentTarget)
                    );
                    router.push(`/search?q=${search_query}`);
                    setOpen(false);
                  }}
                >
                  <input
                    name="search_query"
                    placeholder="Search for tickets"
                    className="w-full py-4 pl-12 pr-20 focus:outline-none rounded-2xl"
                    defaultValue={query}
                    autoComplete="off"
                    onInput={(e) => debouncedSetQuery(e.currentTarget.value)}
                  />
                </form>
                <span
                  className={joinClassNames(
                    "hidden lg:block absolute right-12 top-2.5 py-1.5 px-2 text-sm font-semibold bg-indigo-100 text-indigo-800 rounded-lg transition-opacity ease-in-out duration-200",
                    !query ? "opacity-0" : "opacity-100"
                  )}
                >
                  Press{" "}
                  <kbd className="inline-block font-normal p-px pb-1 rounded bg-gray-300 text-gray-800">
                    <span className="rounded bg-gray-100 px-1 py-0.5">
                      Enter ↩︎
                    </span>
                  </kbd>{" "}
                  for full results
                </span>
                <Dialog.Close className="absolute right-4 top-4">
                  <XMarkIcon className="h-6 w-6 text-gray-500" />
                </Dialog.Close>
                <AnimatePresence>
                  {results &&
                  (results.Events.length > 0 ||
                    results.LandingPages.length > 0) ? (
                    <motion.div
                      layout="position"
                      initial={{ height: 0 }}
                      animate={{ height: "auto" }}
                      exit={{ height: 0 }}
                      className={joinClassNames(
                        "m-4 flex flex-col lg:grid lg:grid-flow-dense gap-4 rounded-xl overflow-y-auto lg:overflow-hidden",
                        results.LandingPages.length > 0
                          ? "lg:grid-cols-2"
                          : "lg:grid-cols-1"
                      )}
                    >
                      <div
                        className={joinClassNames(
                          "flex flex-col gap-2 p-px lg:max-h-[calc(90vh-88px)] lg:overflow-y-auto",
                          results.LandingPages.length > 0
                            ? "lg:col-start-2"
                            : ""
                        )}
                      >
                        {results.Events.map((event, index) => (
                          <Link
                            key={`${event.Name}-${index}-event`}
                            href={getRelativePath(event.Url)}
                            onClick={() => setOpen(false)}
                            className="flex gap-2 w-full hover:bg-indigo-100 transition-colors ease-in-out duration-200 rounded-xl p-1"
                          >
                            <Image
                              src={event.ImageUrl.trim()}
                              alt={event.Name}
                              width={86}
                              height={100}
                              className="rounded-lg h-full aspect-[6/7]"
                            />
                            <div className="flex flex-col gap-0.5">
                              <p className="font-semibold">{event.Name}</p>
                              <p className="text-gray-500">{event.Date}</p>
                              <p className="text-indigo-500">{event.Venue}</p>
                            </div>
                          </Link>
                        ))}
                        <Link
                          href={`/search?q=${query}`}
                          onClick={() => {
                            setOpen(false);
                            setQuery("");
                          }}
                          className="flex items-center gap-1 text-indigo-500"
                        >
                          <p>See more results</p>
                          <ArrowRightIcon className="h-4 w-4" />
                        </Link>
                      </div>
                      {results.LandingPages.length > 0 ? (
                        <div className="flex flex-col gap-4 p-px lg:max-h-[calc(90vh-88px)] lg:overflow-y-auto">
                          {results.LandingPages.map((landingPage, index) => (
                            <Link
                              key={`${landingPage.Name}-${index}-landing-page`}
                              href={landingPage.Url}
                              onClick={() => setOpen(false)}
                              className="relative w-full h-fit group"
                            >
                              <Image
                                src={landingPage.ImageUrl.trim()}
                                alt={landingPage.Name}
                                width={920}
                                height={220}
                                className="object-cover w-full rounded-xl"
                              />
                              <div className="flex gap-0.5 items-center absolute bottom-2 left-2 py-1 px-2 max-w-[calc(100%-1rem)] rounded-lg text-sm font-semibold bg-indigo-100 text-indigo-800 group-hover:opacity-100 opacity-0 transition-opacity ease-in-out duration-200">
                                <p className="truncate">{landingPage.Name}</p>
                                <ArrowRightIcon className="h-4 w-4" />
                              </div>
                            </Link>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}
                    </motion.div>
                  ) : null}
                </AnimatePresence>
                {results &&
                results.LandingPages.length === 0 &&
                results.Events.length === 0 ? (
                  <div className="m-4">
                    <p className="text-lg font-semibold text-black">
                      No matching results
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {open && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay asChild forceMount>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.95 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.125 }}
                className="fixed inset-0 bg-black/40 z-40"
              />
            </Dialog.Overlay>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  );
}
